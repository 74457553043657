import React, { useState, createContext } from 'react'
import FileVUService from '../../services/FileVU/FileVU.service'
import PropTypes from 'prop-types'
import { trackEvent } from '../../utils/functions'

export const FileVUContext = createContext()

export const FileVUContextProvider = (props) => {
  const [allFiles, setAllFiles] = useState({})

  const fetchFiles = async (portalName) => {
    try {
      const res = await FileVUService.fetchFiles(portalName)
      const files = res.data
      const objects = {}
      files?.forEach((file) => {
        const cloudURL = file.cloudURL.split('/')
        if (cloudURL.length > 1) {
          let folderName
          if (file.folderName) {
            folderName = file.folderName
          } else {
            folderName = cloudURL[cloudURL.length - 2]
          }
          if (folderName in objects) {
            objects[folderName].push(file)
          } else {
            objects[folderName] = [file]
          }
        }
      })
      setAllFiles(objects)
    } catch (error) {
      console.log(error)
    }
  }

  const viewFile = async (portalName, key) => {
    try {
      trackEvent('Viewed Recorded Video')
      return await FileVUService.viewFile(portalName, key)
    } catch (error) {
      console.log(error)
    }
  }

  const viewFileById = async (portalName, id) => {
    try {
      trackEvent('Viewed Recorded Video')
      return await FileVUService.viewFileById(portalName, id)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFile = async (fileName, portalName) => {
    return await FileVUService.deleteFile(fileName, portalName)
  }

  const moveFile = async (fileName, portalName, newFolder) => {
    return await FileVUService.moveFile(fileName, portalName, newFolder)
  }

  return (
    <FileVUContext.Provider
      value={{
        allFiles,
        fetchFiles,
        viewFile,
        viewFileById,
        deleteFile,
        moveFile
      }}
    >
      {props.children}
    </FileVUContext.Provider>
  )
}

FileVUContextProvider.propTypes = {
  children: PropTypes.object
}
